.App {
  display: flex;
  flex-direction: column;
}

.intro-section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: auto;
  justify-content: space-around;
}

.intro-section div {
  width: 450px;
  margin: 20px;
  padding: 30px;
}

.imags {
  width: 80%;
  display: flex;
  margin: auto;
  border-radius: 700px;
}

.intro-section-items h1 {
  color: var(--mandys-broun-50);
}

.intro-section-items span {
  color: var(--mandys-pink-300);
}

.intro-section-items p {
  padding-bottom: 28px;
  color: var(--mandys-broun-50);
}

.intro-section-items a {
  padding: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  background-color: var(--mandys-pink-300);
}

.first-aid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 390px;
  padding: 3%;
}

.first-aid h3 {
  color: var(--mandys-broun-50);
  font-size: 30px;
  margin-bottom: 0;
}

.first-aid p {
  font-size: 16px;
  color: var(--mandys-broun-50);
  margin: 24px;
}

.first-aid a {
  color: #fff;
  border: 1px solid var(--mandys-pink-300);
  padding: 10px 15%;
  border-radius: 10px;
  background-color: var(--mandys-pink-300);
  text-decoration: none;
}

.first-aid a:hover {
  color: var(--mandys-pink-300);
  background-color: #fff;
  border: 1px solid var(--mandys-pink-300);
}

.therapies-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.therapies-container h3 {
  font-size: 30px;
  color: var(--mandys-broun-50);
}

.therapies-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.therapies-cards h3 {
  font-size: 20px;
}

.therapies-cards a {
  color: var(--mandys-pink-300);
}

.therapies-cards p {
  color: var(--mandys-broun-50);
}

.therapies-cards div {
  width: 300px;
  padding: 31px;
  height: 280px;
  margin: 10px;
  background-color: #fff;
  justify-content: space-around;
  border-radius: 10px;
}

.therapies-cards img {
  text-align: left;
  margin: auto;
  width: 100px;
  border-radius: 200px;
  scale: var(--scalar-img);
  transition: all 0.5s;
}
