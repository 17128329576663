.first-aid-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 96%;
    margin: auto;
}
.first-aid-container h1{
    text-align: center;
    color: var(--mandys-pink-300);
}
.MuiTypography-root {
    color: var(--mandys-broun-50);
}