.contacto-container{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
}
.contacto-container {
    color: var(--mandys-broun-50);
}
.formulario-container{
    width: 95%;
    margin: auto;
}