.multimedia-container {
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.title-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title-description-container h1 {
  color: var(--mandys-pink-300);
  margin-bottom: 0;
}
.title-description-container p {
  color: var(--mandys-broun-50);
}
.link-multimedia{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.link-multimedia h2 {
  color: var(--mandys-broun-50);
}

.link-multimedia img {
    display: flex;
    padding: 10px;
    width: 130px;
    filter: drop-shadow(0 4px 8px rgba(250, 202, 185, 0.5));
    transition: all .5s;
}
.link-multimedia img:hover{
    scale: 1.2;
}
.img-icons{
    display: flex;
    flex-wrap: wrap;
}
.item-blog{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.item-blog a{
  text-decoration: none;
}
.item-blog h3{
  color: var(--mandys-pink-300);
}
.item-blog p{
  color: var(--mandys-broun-50);
}
.box-blog-text{
  display: none;
}

.box-blog-text-active{
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: var(--mandys-pink-200);
  width: 100%;
  height: 100%;
  top: 0px;
  padding: 1%;
  align-items: center;
}
.close-x{
  margin: 20px;
}

.close-x a{
  text-decoration: none;
  font-size: 20px;
  background-color: #fff;
  color: var(--mandys-pink-300);
  font-weight: 800;
  padding: 10px;
  margin: 20px;
  height: 35px;
  width:  35px;
  border-radius: 50px;
}
.section-blog{
  width: 97%;
  padding: 1%;
  background-color: #fff;
  border: 3px solid var(--mandys-pink-300);
}

.section-blog h1{
  color: var(--mandys-pink-300);
}

.section-blog p{
  color: var(--mandys-broun-50);
}
.section-blog span{
  font-weight: 800;
}
@import url(https://unpkg.com/css-peeps@1/css-peeps.compat.css);

.chica{
  
  --peep-hat-color: #e194cf;
  --peep-hair-color: #a88657;
  --peep-skin-color: #ffffff;
  --peep-accessory-color: #ffffff;
  --peep-facial-hair-color: #ffde24;
  --peep-object-color: #24c8ff;
  --peep-clothes-color: #facab9;

  --peep-body-offset-x: -15px;
  --peep-body-offset-y: -15px;

  display: inline-block;
  width: 288px;
  height: 256px;
  background: white;
  border-radius: 10px;
  border: 2px solid var(--peep-accessory-color);
}