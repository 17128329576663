.Logo{
    width: 200px;
}
.Navmenu-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.menu-inactive {
    list-style: none;
    display: flex;
    flex-direction: row;
}

.Navmenu-container li {
    list-style: none;
}

.Navmenu-container a {
    margin: 38px;
    text-decoration: none;
    color: var(--mandys-broun-50);
    cursor: pointer;
    font-weight: 400;
}

.Navmenu-container a:hover {
    font-weight: 600;
    scale: 1.5;
    color: var(--mandys-pink-300);
}
.icon-menu{
    display: none;
}
@media screen and (max-width: 500px) {
    .icon-menu{
        display: flex;
        flex-direction: column;
    }
    .icon-menu span{
        background-color: var(--mandys-pink-300);
        width: 45px;
        height: 5px;
        margin: 5px;
        border-radius: 5px;
    }
    .Navmenu-container{
        
    }
    .menu-active{
        transition: all .5s;
        flex-direction: column;
        position: absolute;
        top: 102px;
        right: 0px;
        background-color: #fff;
        height: 100vh;
    }
    .menu-active li{
        padding: 20px;
    }
    .menu-inactive{
        display:none;
    }
}
