.Nosotros-container{
    display: flex;
    flex-direction: column;    
}
.Nosotros-presentacion{
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 7%;
}
.Nosotros-presentacion h1{
    color: var(--mandys-pink-300);
}
.Nosotros-presentacion-texto{
    width: 500px;
}
.Nosotros-presentacion-texto p{
    color: var(--mandys-broun-50);
}
.Nosotros-social a{
    margin: 20px;
}
.Nosotros-social svg:hover{
    transition: all .5s;
    scale: 1.2;
}
.Nosotros-presentacion img{
    height: 400px;
    border-radius: 200px;
}
.conocimiento{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 50px 150px 50px 150px;
}
.conocimiento div{
    margin: 20px;
}
.conocimiento h2{
    color: var(--mandys-pink-300);
}
.conocimiento p{
    color: var(--mandys-broun-50);
}
.conocimiento img{
    width: 150px;
}
.experiencia-container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #fff;
}
.experiencia-container h2{
    color: var(--mandys-pink-300);
}
.experiencia-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 50px;
    align-items: center;
}

.experiencia-items img{
    width: 190px;
    margin: 15px;
    display: flex;
}