.contenido-buttons {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 30%;
}

.button-section {
  text-decoration: none;
  color: #fff;
  border: 1px solid var(--mandys-pink-300);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--mandys-pink-300);
  transition: all 0.5s;
}
.button-section:hover {
  scale: 1.2;
}

.button-section-active {
  text-decoration: none;
  color: var(--mandys-pink-300);
  border: 1px solid var(--mandys-pink-300);
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  transition: all 0.5s;
}
.button-section-active:hover {
  scale: 1.2;
}
.secction-contenido {
  margin-top: 10px;
  border-top: 1px solid var(--mandys-pink-300);
}
.selection-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--mandys-broun-50);
}
.selection-container-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
  margin: auto;
}

.container-img-card {
  z-index: -1;
  margin: 15px;
  display: flex;
  height: 300px;
  width: 300px;
  position: relative;
  --degradado: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
}
.container-img-card:hover {
  --degradado: polygon(0 100%, 100% 100%, 100% 0, 0 0);
}
.card-img {
  position: absolute;
}
.card-img img {
  width: 300px;
}
.card-title {
  transition: all 0.5s;
  width: 300px;
  background: rgb(250, 202, 185);
  background: linear-gradient(
    0deg,
    rgba(250, 202, 185, 1) 31%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  top: 230px;
  left: 0px;
  clip-path: var(--degradado);
}
.card-title h3 {
  color: #fff;
  filter:drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
}
