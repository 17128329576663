.footer-container{
    border-top: 1px solid var(--mandys-pink-300);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
}
.footer-container-item{
    display: flex;
    flex-direction: column;
    width: 500px;  
    margin: 10px;
}
.footer-container-item h3{
    color: var(--mandys-broun-50);
    margin-bottom: 0;
}
.footer-container-item p{
    color: var(--mandys-broun-50);
}
.footer-container-item a{
    text-decoration: none;
    color: var(--mandys-broun-50);
    margin: 20px;
}
.footer-container-item label{
    color: #fff;
}