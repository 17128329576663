body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--mandys-pink-50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --mandys-broun-50: #B69B8E;

  --mandys-pink-50: #fffbfb;
  --mandys-pink-100: #fdeae3;
  --mandys-pink-200: #fcd9cc;
  --mandys-pink-300: #facab9;
  --mandys-pink-400: #f39776;
  --mandys-pink-500: #e9744a;
  --mandys-pink-600: #d5592d;
  --mandys-pink-700: #b34822;
  --mandys-pink-800: #943e20;
  --mandys-pink-900: #7b3921;
  --mandys-pink-950: #431a0c;
}