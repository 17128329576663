
input{
 border: 1px solid var(--mandys-broun-50);
 border-radius: 10px;
 margin: 5px;
 height: 20px;
}
label{
    margin: 5px;
}
select{
    border: 1px solid var(--mandys-broun-50);
    border-radius: 10px;
    margin: 5px;
    height: 20px;
}
textarea{
    border: 1px solid var(--mandys-broun-50);
    border-radius: 10px;
    margin: 5px;
    height: 300px;
    width: 50%;
}
.MuiInputBase-input{
margin-top: 5px;

}
.send-button{
    border: none;
    margin: 10px;
    padding: 10px;
    border-radius: 100px;
    color: #fff;
    font-weight: 800;
    background-color: var(--mandys-pink-300);
    font-size: 20px;
}

.send-button:hover{
    transition: all .5s;
    background-color: var(--mandys-pink-300);
    border: 1px solid var(--mandys-broun-50);
    color: #fff;
}
.buttons{
    padding: 20px;
    background-color: var(--mandys-pink-300);
    color: #fff;
    font-weight: 800;
    font-size: 16px;
    border-radius: 100px;
    border: none;
}